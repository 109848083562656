const PrivacyPolicy = () => {
    return (
      <div className="max-w-3xl mx-auto p-8 bg-gradient-to-r from-blue-50 to-white shadow-xl rounded-2xl">
        <h1 className="text-3xl font-extrabold text-center text-blue-600 mb-6">Privacy Policy</h1>
        <p className="mb-6 text-gray-700 text-lg text-center">
          Your privacy is important to us. This policy explains how we handle your data.
        </p>
  
        <div className="space-y-6 text-gray-800">
          <section>
            <h2 className="text-2xl font-semibold text-blue-500">1. Information We Collect</h2>
            <p>
                The current version 1.1.0 of <strong>QuizIT-The Oficer's Quiz (henceforth reffered to as "QuizIT")</strong> does not collect any data.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold text-blue-500">2. Content in the App</h2>
            <p>
                The conent within app has been acquired from open source resources across the web. Conent in our application does not 
                violate Conetnt Policy of any other external company or service providers. 
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold text-blue-500">4. Third-Party Services</h2>
            <p>
              Our app does not collaborate nor have any tie-ups with any Third-Party Services. We strictly prefer to develop apps in-house.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold text-blue-500">5. Changes to This Policy</h2>
            <p>
              We reserve the right to update this Privacy Policy. Changes will be communicated through app updates or notifications.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-semibold text-blue-500">6. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at
              <span className="font-bold text-blue-600"> <><strong>arcanestudios80@gmail.com</strong></></span>.
            </p>
          </section>
        </div>
      </div>
    );
  };
  
  export default PrivacyPolicy;
  