
import PrivacyPolicy from './Components/PraivacyPolicy.jsx';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from './Components/NotFound.jsx';
import Home from './Components/Home.jsx';
import TabLayout from './Components/TabLayout.jsx';
import Login from './Components/Login.jsx';
function App() {
  return (
    <Router>
      <Routes>
        <Route   path="/" element={<Home />} />
        <Route   path="/privacy" element={<PrivacyPolicy />} />
        <Route   path="/tab" element={<TabLayout />} />
        <Route   path="/login" element={<Login />} />
        <Route   path="*" element={<NotFound />} />
      </Routes>
    </Router>

  );
}

export default App;
