import { useState } from "react";
import PrivacyPolicy from "./PraivacyPolicy";

const tabs = [
  { id: "duoflash", label: "DuoFlash", content: <PrivacyPolicy/> },
  { id: "quizit", label: "QuizIT", content: "Learn more about us here." },
  { id: "contact", label: "Contact", content: "Get in touch with us." }
];

export default function TabLayout() {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <div className="w-full mx-auto mt-10 p-4 bg-white shadow-lg rounded-lg">
      {/* Tab Buttons */}
      <div className="flex max-w-md border-b">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`flex-1 py-2 text-center text-lg font-medium transition-colors duration-300 ${
              activeTab === tab.id ? "border-b-4 border-blue-500 text-blue-500" : "text-gray-600 hover:text-blue-500"
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      
      {<h1>Tab 1</h1>}
      <div className="p-4 text-center text-gray-800 text-lg mt-4">
        {tabs.find((tab) => tab.id === activeTab)?.content}
      </div>
    </div>
  );
}