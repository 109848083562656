const LoginFormCard = () =>{

    return(
        <div className=" h-auto p-8 rounded-lg bg-blue-500">
            <form>
              <div className="mb-4">
                <label htmlFor="email">Email</label>
                <input className="p-4" id="email" type="email" placeholder="Enter your email" required />
              </div>
              <div className="mb-4 border-l-gray-500">
                <label htmlFor="password">Password</label>
                <input className="p-4" id="password" type="password" placeholder="Enter your password" required />
              </div>
              <button className="bg-gray-400 border-lg w-full"> Login </button>
            </form>
    </div>
    )
}

export default LoginFormCard;