import { useRef, useState } from "react";
import OptionInput from "./OptionInput";
import AnswerSelectionDropBox from "./AnswerSelectionDropDown";
import SavingModal from "./SavingQuestionModal";

export default function Question() {
  const [isOpen, setOpen] = useState(false);

  const questionRef = useRef();
  const questionDecriptionRef = useRef();
  const optionARef = useRef();
  const optionBRef = useRef();
  const optionCRef = useRef();
  const optionDRef = useRef();
  const correctAnswerRef = useRef();
  const answerDecriptionRef = useRef();
  const previousYearRef = useRef();
  const saveDialogRef = useRef();

  async function handleSubmit() {
    const question_text = questionRef.current.value;
    const question_description = questionDecriptionRef.current.value;
    const option_a = optionARef.current.value;
    const option_b = optionBRef.current.value;
    const option_c = optionCRef.current.value;
    const option_d = optionDRef.current.value;
    const answer = correctAnswerRef.current.value;
    const answer_description = answerDecriptionRef.current.value;
    const previous_year = previousYearRef.current.value;

    const dataString = JSON.stringify({
      question_text,
      question_description,
      option_a,
      option_b,
      option_c,
      option_d,
      answer,
      answer_description,
      previous_year,
    });

    const response = await fetch("http://localhost:3000/app/questions/add-question",
      {
        method:"POST",
        body:dataString,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }
    );

    if(!response.ok){
      console.log("Something went wrong")
    }


    setOpen(true)

    setTimeout(() => {
      //console.log(response)
      setOpen(false)
    }, 3000);
  }
  return (
    <>
      <SavingModal
        ref={saveDialogRef}
        isOpen={isOpen}/>

      <section className="input-group">
        <label htmlFor="question-text">Question</label>
        <textarea ref={questionRef} id="question-text" required />

        <label htmlFor="question-description">Question Description</label>
        <textarea ref={questionDecriptionRef} id="question-description" />

        <OptionInput
          ref={optionARef}
          inputId="option-a"
          labelText="Option A"
          inputType="text"
          isRequired={true}
        />

        <OptionInput
          ref={optionBRef}
          inputId="option-b"
          labelText="Option B"
          inputType="text"
        />

        <OptionInput
          ref={optionCRef}
          inputId="option-c"
          labelText="Option C"
          inputType="text"
        />

        <OptionInput
          ref={optionDRef}
          inputId="option-d"
          labelText="Option D"
          inputType="text"
        />

        <OptionInput
          ref={previousYearRef}
          inputId="previous-year"
          labelText="Previous Year"
          inputType="number"
          max={2070}
        />

        <AnswerSelectionDropBox
          answerList={["A", "B", "C", "D", "E"]}
          ref={correctAnswerRef}
          isRequired={true}
        />

        <label htmlFor="answer-description">Answer Description</label>
        <textarea ref={answerDecriptionRef} id="answer-description" />

        <div id="submit">
          <button onClick={handleSubmit}>Add Question</button>
        </div>
      </section>
    </>
  );
}
