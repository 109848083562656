import LoginFormCard from "./LoginFormCard";

const LoginForm = () =>{
    return (
        <div className="w-full h-full p-4 flex justify-center content-center">
        <LoginFormCard />
        </div>
    )
}

export default LoginForm;