import Header from './Header.jsx';
import Question from './Question.jsx';
const Home = () =>{

    return(
        <><Header/>
        <Question/>
          </>
    )

} 

export default Home;