import LoginNavbar from "./LoginNavbar"
import LoginForm from "./LoginForm"
const Login = () =>{

    return(
    <div className="flex flex-1 flex-col h-screen">
    <LoginNavbar/>
    <LoginForm/>

    </div>)
}

export default Login;