const LoginNavbar = () =>{
return(
    <header>
    <nav className="w-full p-4 m-4 rounded-lg bg-white">
        <h1 className="text-gray-900 font-mono text-xl">
            Login Page
        </h1>
    </nav>
    </header>
)
}

export default LoginNavbar;